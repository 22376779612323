import React, { useEffect, useState } from 'react';
import { withNamespaces } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import moment from 'moment';
import greyBorder from '@assets/pictures/svgs/grey-border.svg';
import mail from '@assets/pictures/svgs/private-final-ticket/mail.svg';
import mobile from '@assets/pictures/svgs/private-final-ticket/mobile.svg';
import share from '@assets/pictures/svgs/private-final-ticket/share.svg';
import star from '@assets/pictures/svgs/private-final-ticket/star.svg';
import ticket from '@assets/pictures/svgs/private-final-ticket/ticket.svg';
import close from '@assets/pictures/svgs/close.svg';
import { ReactComponent as ArrowBack } from '@assets/pictures/svgs/arrow-back.svg';
import { ReactComponent as ArrowBackActive } from '@assets/pictures/svgs/arrow-back-active.svg';
import useIsMobile from '../customHooks/useIsMobile';

import poster2 from '@assets/pictures/poster-2.png';
import gogoLogo from '@assets/pictures/svgs/gogo-logo.svg';
import eyeGreen from '@assets/pictures/svgs/eye-green.svg';
import barCode from '@assets/pictures/svgs/bar-code.svg';
import poster from '@assets/pictures/poster.png';
import logo from '@assets/pictures/svgs/private-final-ticket/logo.svg';
import logoBlack from '@assets/pictures/svgs/private-final-ticket/logo-black.svg';
import qr from '@assets/pictures/svgs/private-final-ticket/qr.svg';
import { ReactComponent as Sharegolden } from '@assets/pictures/svgs/sharegold.svg';
import { ReactComponent as Emailgolden } from '@assets/pictures/svgs/emailgold.svg';
import { ReactComponent as Messagegolden } from '@assets/pictures/svgs/messagegold.svg';
import { ReactComponent as TicketImg } from '@assets/pictures/svgs/ticketimg.svg';
import { ReactComponent as TicketImggold } from '@assets/pictures/svgs/ticketimggold.svg';
import Diamond from '@assets/pictures/Diamond.png';

import {
  clearState,
  fetchReservationDetails,
  fetchReservationDetailsBrij,
  sendEmail,
  sendEmailUnpaid,
  sendSms,
} from '../store/movies/movieActions';
import ShareTicketPopup from './partials/popups/ShareTicketPopup.jsx';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { BASEURL, HOST_URL } from '../config/index';
import Spinner from './partials/Spinner';
import { Modal } from 'react-bootstrap';
import { currencyFormatter } from '../helper/currencyFormatter';
import dummyPosterStatic from '@assets/pictures/dummyPoster.png';
import { getLangSpecificAttribute } from '@helper/languages';

import { setReservationID } from '../store/seatLayout/seatLayoutActions';
import _ from 'lodash';
import QRCode from 'qrcode.react';

const FinalTicketUnpaid = ({ t }) => {
  const isMobile = useIsMobile();
  const { reservation_id, category_id } = useParams();

  useEffect(() => {
    category_id == 1
      ? dispatch(fetchReservationDetails({ reservation_id, category_id }))
      : dispatch(fetchReservationDetailsBrij({ reservation_id, category_id }));
    dispatch(setReservationID(reservation_id));
  }, []);

  // useEffect(() => dispatch(clearState()), []);
  const [modalShow, setModalShow] = useState(false);
  const [actionsModal, setActionsModal] = useState(false);
  const [back, setBack] = useState(false);
  let getLanguageSpecificMovieDetails = useSelector(
    (state) => state.movies.getLanguageSpecificMovieDetails,
  );
  const movieDetails = useSelector((state) => state.movies.movieDetails);
  const reservationDetails = useSelector(
    (state) => state?.movies?.reservationDetails,
  );
  const reservationDetailsLoading = useSelector(
    (state) => state?.movies?.reservationDetailsLoading,
  );

  const add_ons_array = useSelector(
    (state) => state?.movies?.reservationDetails?.add_ons_array,
  );

  const dispatch = useDispatch();
  const history = useHistory();
  const showBookingID = useSelector((state) => state.movies.showBookingID);
  const bookingDetails = useSelector((state) => state.movies.bookingDetails);
  let private_rate_card_detail = reservationDetails?.private_rate_card_detail ?? [];

  const bookingDetailsLoading = useSelector(
    (state) => state.movies.bookingDetailsLoading,
  );
  const organization = useSelector((state) => state.movies.organizationDetails);
  const {
    org_logo_url,
    org_mycinema_logo,
    org_name,
    dummy_poster: dummyPosterDynamic,
  } = organization;

  const dummyPoster = window.localStorage.getItem('placeholder_image')
    ? window.localStorage.getItem('placeholder_image')
    : dummyPosterStatic;

  const [sendEmailLoading, setSendEmailLoading] = useState(false);
  const [sendSmsLoading, setSendSmsLoading] = useState(false);
  const [seatTypes, setSeatTypes] = useState([]);

  const currency = useSelector((state) => state.movies.currency);
  const [loading, setLoading] = useState({
    email: false,
    sms: false,
  });
  const { curr_code, curr_id } = currency;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const onClickEmailTicket = () => {
    setSendEmailLoading(true);
    dispatch(sendEmailUnpaid({ reservation_id })).finally(() => {
      setSendEmailLoading(false);
    });
  };

  const onClickSmsTicket = () => {
    setSendSmsLoading(true);
    dispatch(sendSms({ reservation_id })).finally(() => {
      setSendSmsLoading(false);
    });
  };

  const selected_language = useSelector((state) => state.movies.selected_language);

  const { lang_name, iso_2, lang_id } = selected_language;

  let genresTranslated = (reservationDetails?.movie_genres || [])
    .map((genre) => genre[iso_2?.toLowerCase()])
    .filter((x) => x)
    .splice(0, 13)
    .join(', ');

  useEffect(() => {
    function extractSeatTypes(selectedSeats) {
      return _.chain(selectedSeats)
        .groupBy('screen_seat_type_id')
        .toPairs()
        .map((x) => ({
          sst_seat_type: x[1][0].sst_seat_type,
          seats: x[1],
        }))
        .map((x) => {
          x.seats = _.chain(x.seats)
            .groupBy('rate_card_group_child_id')
            .toPairs()
            .map((x) => ({
              tt_name: x[1][0].tt_name,
              ticket_type_translations: x[1][0].ticketTypeTranslations,
              no_of_seats: x[1].length,
              single_seat_price: x[1][0].rc_total_amount,
              single_seat_net_price: x[1][0].rc_net_amount,
              total_price: x[1].reduce((acc, x) => acc + x.rc_total_amount, 0),
              seats: x[1],
            }))
            .value();
          return x;
        })
        .value();
    }

    let seatTypes = extractSeatTypes(
      reservationDetails?.selectedSeats.length
        ? reservationDetails?.selectedSeats
        : [],
    );
    let finalResult = [];
    for (let seatType of seatTypes) {
      let seats = seatType.seats.map(
        (x) =>
          `${x.no_of_seats} ${
            x?.ticket_type_translations?.[iso_2?.toLowerCase()] || x?.tt_name
          }`,
      );
      finalResult.push({
        ticket_type: seatType.sst_seat_type,
        seat_types: seats,
      });
    }
    setSeatTypes(finalResult);
  }, [iso_2, reservationDetails]);

  const booking_type = useSelector((state) => state.movies.booking_type);

  const loginDetails = useSelector((state) => state.auth);
  const { loginError, loggedInUser, guestUser, userHistory } = loginDetails;

  function extractSeatTypes(selectedSeats) {
    let result = _.chain(selectedSeats)
      .groupBy('screen_seat_type_id')
      .toPairs()
      .map((x) => ({
        sst_seat_type: x[1][0].sst_seat_type,
        seats: x[1],
      }))
      .map((x) => {
        x.seats = _.chain(x.seats)
          .groupBy('rate_card_group_child_id')
          .toPairs()
          .map((x) => ({
            tt_name: x[1][0].tt_name,
            ticket_type_translations: x[1][0].ticketTypeTranslations,
            no_of_seats: x[1].length,
            single_seat_price: x[1][0].rc_total_amount,
            single_seat_net_price: x[1][0].rc_net_amount,
            total_price: x[1].reduce((acc, x) => acc + x.rc_total_amount, 0),
            seats: x[1],
          }))
          .value();
        return x;
      })
      .value();
    return result;
  }
  function percentage(percent, total) {
    return (percent / 100) * total;
  }

  function getRateCardAddonsTotal(add_ons_array = []) {
    if (!add_ons_array) return 0;
    if (add_ons_array?.length === 0) return 0;

    return add_ons_array?.[0]?.BoxOfficeFinal?.TotalAddons ?? 0;
  }

  if (reservationDetailsLoading)
    return (
      <div className="col-12">
        <div className="col-12 text-center">
          <Spinner />
        </div>
      </div>
    );

  return (
    <section className="">
      <div className="header-space ticket-page">
        {/*container-fluid */}
        {false && !isMobile && (
          <section className="row for-prev-step mx-0">
            <button
              className="btn-main my-3 my-xl-4"
              onClick={() => history.go(-1)}
              onMouseOver={() => setBack(true)}
              onMouseOut={() => setBack(false)}
            >
              {/* <img
                src={back ? arrowBackActive : arrowBack}
                className="mr-3"
                alt=""
              /> */}
              {back ? <ArrowBackActive /> : <ArrowBack />}
              Back
            </button>
          </section>
        )}{' '}
        <>
          <div className="position-relative overflow-hidden main_wrapper_final">
            <div className="final-ticket">
              <div
                className={`ticket-header ${
                  reservationDetails?.booking_type_id === 3 ? 'back_gold_final' : ''
                }`}
                height={`${isMobile ? '40px' : '28px'}`}
                width={`${isMobile ? '80px' : ''}`}
              >
                <div>
                  <Link to>
                    <img
                      src={org_mycinema_logo || org_logo_url}
                      alt={org_name}
                      height={`${isMobile ? '40px' : '80px'}`}
                      width={`${isMobile ? '80px' : '130px'}`}
                      // className="img-fluid"
                    />
                  </Link>
                </div>
                <div className="final_svg_top" style={{ alignItems: 'center' }}>
                  {reservationDetails?.booking_type_id === 3 ? (
                    <TicketImggold
                      className="svg_ticket"
                      style={{ marginRight: '9px' }}
                    />
                  ) : (
                    <TicketImg style={{ marginRight: '9px', marginTop: '2px' }} />
                  )}

                  <h6
                    className="mb-0"
                    style={{
                      color:
                        reservationDetails?.booking_type_id === 3
                          ? 'black'
                          : 'white',
                    }}
                  >
                    {reservationDetails?.booking_type_id === 3
                      ? t('Private Cinema')
                      : t('Your Ticket')}
                  </h6>
                </div>
              </div>
              {bookingDetailsLoading ? (
                <div className="row my-5">
                  <div className="col-12 text-center">
                    <Spinner />
                  </div>
                </div>
              ) : (
                <div className="ticket-content movie_info_wrapper payment_details_new">
                  <div className="row top">
                    <div
                      className="col-md-9"
                      style={{
                        backgroundImage:
                          reservationDetails?.booking_type_id === 3
                            ? `url(${Diamond})`
                            : undefined,
                        backgroundRepeat:
                          reservationDetails?.booking_type_id === 3
                            ? 'no-repeat'
                            : undefined,
                        backgroundPosition:
                          reservationDetails?.booking_type_id === 3
                            ? 'center'
                            : undefined,
                      }}
                    >
                      <section className="row align-items-center">
                        {/* col-4 col-md-3 */}
                        <div className="col-12 d-flex align-items-center">
                          <img
                            src={
                              getLangSpecificAttribute(
                                reservationDetails?.languageSpecificImages,
                                lang_id,
                                'artwork',
                              ) ||
                              movieDetails?.md_thumbnail_url ||
                              reservationDetails?.md_thumbnail_url ||
                              dummyPoster
                            }
                            alt="Movie Img"
                            onError={({ currentTarget }) => {
                              currentTarget.onerror = () => {}; // prevents looping
                              currentTarget.src = dummyPosterStatic;
                            }}
                            className="movie-img img-fluid final_image"
                          />
                          {/* </div>
                          <div className="col-8 col-md-9"> */}
                          <article className="">
                            <p
                              className={`mb-1 final_heading ${
                                reservationDetails?.booking_type_id === 3
                                  ? 'final_heading_gold'
                                  : ''
                              }`}
                            >
                              {reservationDetails?.booking_type_id === 3
                                ? t('Private Cinema')
                                : t('Now Showing')}
                            </p>
                            <h1
                              className="movie_info_title mb-2"
                              style={{ color: '#000' }}
                            >
                              {getLangSpecificAttribute(
                                reservationDetails?.languageSpecificImages,
                                lang_id,
                                'mc_title',
                              ) || reservationDetails?.movie_title}
                            </h1>
                            <div
                              className="movie_info_rating_n_format justify-content-start res_final_spacing"
                              style={{
                                marginBottom: !isMobile ? '24px' : undefined,
                              }}
                            >
                              <span
                                className="movie_info_language"
                                style={{
                                  backgroundColor:
                                    reservationDetails?.booking_type_id === 3
                                      ? '#C09E3F'
                                      : '#00aad3',
                                }}
                              >
                                {(!!t(reservationDetails?.lang_name)
                                  ? t(reservationDetails?.lang_name)
                                  : reservationDetails?.lang_name) ||
                                  reservationDetails?.version_name}
                                {`${
                                  reservationDetails?.sub_1_iso_2
                                    ? `/${reservationDetails?.sub_1_iso_2}`
                                    : ''
                                }${
                                  reservationDetails?.sub_1_iso_2 &&
                                  reservationDetails?.sub_2_iso_2
                                    ? `-${reservationDetails?.sub_2_iso_2}`
                                    : ''
                                }`}
                              </span>

                              {
                                <>
                                  {reservationDetails?.duration && (
                                    <button className="newtime_final">
                                      {reservationDetails?.duration}{' '}
                                      {t('common.mins')}
                                    </button>
                                  )}
                                  {/*<span className="movie_info_language">
                                        {(!!t(reservationDetails?.lang_name) ? t(reservationDetails?.lang_name) : reservationDetails?.lang_name)
                                        || reservationDetails?.version_name}{`${
                                          reservationDetails?.sub_1_iso_2 ? `/${reservationDetails?.sub_1_iso_2}` : ''
                                      }${
                                          reservationDetails?.sub_1_iso_2 && reservationDetails?.sub_2_iso_2 ? `-${reservationDetails?.sub_2_iso_2}` : ''
                                      }`}
                                    </span>*/}
                                  {reservationDetails?.rating && (
                                    <span className="movie_info_rating">
                                      {reservationDetails?.rating}
                                    </span>
                                  )}
                                  {reservationDetails?.mf_name && (
                                    <span className="movie_info_language">
                                      {reservationDetails?.mf_name}
                                    </span>
                                  )}
                                </>
                              }
                            </div>

                            <div className=" text-left res_final_spacing">
                              <h6
                                className={`final_heading 
                                ${
                                  reservationDetails?.booking_type_id === 3
                                    ? 'gold_final_text'
                                    : ''
                                }`}
                              >
                                {t('Date & Time')}
                              </h6>
                              <p className="final_para text-uppercase">
                                {moment(
                                  reservationDetails?.ss_start_date ||
                                    reservationDetails?.ss_start_actual_date,
                                ).format('DD MMM YYYY')}{' '}
                                |{' '}
                                {moment(
                                  reservationDetails?.ss_start_show_time,
                                  'HH:mm:ss',
                                ).format('HH:mm')}
                              </p>
                              {/*
                                    reservationDetails?.booking_type_id === 3 && (<>
                                    <img src={Diamond} alt="diamond" />
                                    </>)*/}

                              {/*<h6 className="">{t("Genre")}</h6>
                                  <p/>
                        {!!(reservationDetails?.genres_with_translations
                                        ?.filter(
                                            (genre) => genre[iso_2?.toLowerCase()]
                                        )
                                        ?.map(
                                            (genre) => genre[iso_2?.toLowerCase()]
                                        )
                                        .splice(0, 13)
                                        .join(", "))
                                    &&
                                    <p>
                                    { (<>
                                          {reservationDetails?.genres_with_translations
                                              ?.filter(
                                                  (genre) => genre[iso_2?.toLowerCase()]
                                              )
                                              ?.map(
                                                  (genre) => genre[iso_2?.toLowerCase()]
                                              )
                                              .splice(0, 13)
                                              .join(", ") || reservationDetails?.g_name}
                                        </>)}
                                              </p>}*/}
                            </div>

                            <div className=" text-left res_final_spacing">
                              <h6 className="final_heading">
                                {t('Transaction Date & Time')}
                              </h6>
                              <p className="final_para text-uppercase">
                                {moment(
                                  reservationDetails?.transaction_date_time,
                                ).format('DD MMM YYYY')}{' '}
                                |{' '}
                                {moment(
                                  reservationDetails?.transaction_date_time,
                                ).format('HH:mm')}
                              </p>
                            </div>
                            <div className="text-left res_final_spacing ">
                              <h6
                                className={`final_heading 
                                  ${
                                    reservationDetails?.booking_type_id === 3
                                      ? 'gold_final_text'
                                      : ''
                                  }`}
                              >
                                {t('Cinema')}
                              </h6>
                              <p />
                              <p className="final_para">
                                {reservationDetails?.cine_name},{' '}
                                {reservationDetails?.cine_address}
                                {/* {reservationDetails?.cine_location} */}
                              </p>
                            </div>
                            {/* <div className="features">
                                <p>
                                  {
                                    reservationDetails?.is_version_apply
                                        ? <>{bookingDetails.version_name}{" "}
                                          {reservationDetails?.mf_name}</>
                                        : <>{reservationDetails?.lang_name}{" "}
                                          {reservationDetails?.mf_name}</>
                                  }
                                </p>
                                <p>
                                  {
                                    !!(reservationDetails?.genres_with_translations
                                        ?.filter(
                                            (genre) => genre[iso_2?.toLowerCase()]
                                        )
                                        ?.map(
                                            (genre) => genre[iso_2?.toLowerCase()]
                                        )
                                        .splice(0, 13)
                                        .join(", "))
                                      ? (<>
                                          {reservationDetails?.genres_with_translations
                                              ?.filter(
                                                  (genre) => genre[iso_2?.toLowerCase()]
                                              )
                                              ?.map(
                                                  (genre) => genre[iso_2?.toLowerCase()]
                                              )
                                              .splice(0, 13)
                                              .join(", ") || reservationDetails?.g_name}{" "}
                                          | {reservationDetails?.rating}
                                        </>)
                                      : (<>
                                          {reservationDetails?.rating}
                                        </>)
                                  }
                                </p>
                              </div>
                              <div className="cinema-name">
                                <p className="fs-16 d-none d-md-block">
                                  {t("Cinema")}:
                                </p>
                                <p className="font-weight-bold">
                                  {reservationDetails?.cine_name},{" "}
                                  {reservationDetails?.cine_address}
                                   {reservationDetails?.cine_location}
                                </p>
                              </div> */}
                          </article>
                        </div>
                      </section>
                      {!isMobile && (
                        <div>
                          <p className="special_final">
                            {!!reservationDetails?.special_request &&
                              reservationDetails?.special_request}
                          </p>
                        </div>
                      )}
                    </div>

                    <div className="col-md-3 mt-md-0 qr_sec">
                      <div className="ticket_text">
                        <div
                          className={`final_heading final_heading_mob ${
                            reservationDetails?.booking_type_id === 3
                              ? 'gold_final_text'
                              : ''
                          }`}
                        >
                          {t('Reservation ID')}:
                        </div>
                        <div className="final_para final_para_mob">
                          {reservation_id}
                        </div>
                      </div>
                      <p className="qr_para">
                        <span></span>
                      </p>
                      {reservation_id && (
                        <>
                          <QRCode
                            value={`${BASEURL}/your-ticket/${reservation_id}`}
                            className="mx-auto ml-md-auto mr-md-0 qr-border qr_final"
                          />
                          <p className="qr_para">
                            {/* {t("Scan to get a ticket on your mobile")} */}
                            <span>{t('Use QR code for all tickets')}</span>
                          </p>
                        </>
                      )}

                      {isMobile && (
                        <div>
                          <p className="special_final_mob">
                            {!!reservationDetails?.special_request &&
                              reservationDetails?.special_request}
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                  {/* Dotted Line <div className="grey-dashed-border py-4">
                      <img
                        src={greyBorder}
                        alt="border"
                        className="img-fluid"
                      />
                        </div>*/}
                  <div className="row bottom align-items-center">
                    <div
                      className={
                        isMobile
                          ? 'left grid_mob_space'
                          : 'col-md-12 col-lg-12 col-xl-12 left grid_mob_space'
                      }
                    >
                      <div
                        className={`grid-col-1-mob ${
                          reservationDetails?.booking_type_id === 3
                            ? 'back_gold_final'
                            : ''
                        }`}
                      >
                        <div
                          className={`grid-col-1 mb-4 mb-md-0 ${
                            reservationDetails?.booking_type_id === 3
                              ? 'back_gold_final'
                              : ''
                          }`}
                          style={{ padding: isMobile ? '25px' : undefined }}
                        >
                          <div>
                            <div
                              className={`heading 
                            ${
                              reservationDetails?.booking_type_id === 3
                                ? ''
                                : 'heading_mob'
                            } 
                            ${
                              reservationDetails?.booking_type_id === 3
                                ? 'heading_golden_final'
                                : ''
                            }`}
                            >
                              {t('Auditorium')}
                            </div>
                            <div
                              className={`content ${
                                reservationDetails?.booking_type_id === 3
                                  ? 'golden_content_final'
                                  : ''
                              }`}
                            >
                              {reservationDetails?.screen_name}
                            </div>
                          </div>
                          {reservationDetails?.booking_type_id !== 3 && (
                            <>
                              <div className="">
                                {/*ticket-type-col*/}
                                <div
                                  className={`text heading 
                              ${
                                reservationDetails?.booking_type_id === 3
                                  ? ''
                                  : 'heading_mob'
                              }
                              ${
                                reservationDetails?.booking_type_id === 3
                                  ? 'heading_golden_final'
                                  : ''
                              }`}
                                >
                                  {t('Ticket Type')}
                                </div>
                                {reservationDetails?.booking_type_id !== 3 && (
                                  <div>
                                    {seatTypes?.length > 0 &&
                                      seatTypes.map((seatType, seatTypeIndex) => (
                                        <div
                                          className={`content content_mob ${
                                            reservationDetails?.booking_type_id ===
                                            3
                                              ? 'golden_content_final'
                                              : ''
                                          }`}
                                          key={seatTypeIndex}
                                        >
                                          <div>
                                            <span className="">
                                              {seatType.ticket_type}&nbsp;
                                              {!isMobile && '-'}&nbsp;
                                            </span>
                                            {seatType.seat_types.map(
                                              (item, index) => (
                                                <span
                                                  className={`${
                                                    isMobile ? 'd-block' : ''
                                                  }`}
                                                >
                                                  {item}
                                                  {seatType.seat_types.length -
                                                    1 !==
                                                    index && ', '}
                                                </span>
                                              ),
                                            )}
                                          </div>
                                        </div>
                                      ))}
                                  </div>
                                )}
                              </div>
                            </>
                          )}

                          {reservationDetails?.booking_type_id != 3 && (
                            <div>
                              <div
                                className={`heading 
                              ${
                                reservationDetails?.booking_type_id === 3
                                  ? ''
                                  : 'heading_mob'
                              }
                              ${
                                reservationDetails?.booking_type_id === 3
                                  ? 'heading_golden_final'
                                  : ''
                              }`}
                              >
                                {t('No. of Seats')}
                              </div>
                              <div
                                className={`content ${
                                  reservationDetails?.booking_type_id === 3
                                    ? 'golden_content_final'
                                    : ''
                                }`}
                              >
                                {reservationDetails?.ticket_count}
                              </div>
                            </div>
                          )}
                          <div>
                            <div
                              className={`heading 
                            ${
                              reservationDetails?.booking_type_id === 3
                                ? ''
                                : 'heading_mob'
                            }
                            ${
                              reservationDetails?.booking_type_id === 3
                                ? 'heading_golden_final'
                                : ''
                            }`}
                            >
                              {t('Selected Seats')}:
                            </div>
                            <div
                              className={`comma-separated content ${
                                reservationDetails?.booking_type_id === 3
                                  ? 'golden_content_final'
                                  : ''
                              }`}
                            >
                              <span>{reservationDetails?.seat_name}</span>
                              {reservationDetails?.booking_type_id == 3 && (
                                <p>{t('entire-hall-booked')}</p>
                              )}
                            </div>
                          </div>

                          {/* <div>
                            <p>{t("Seat Type")}</p>
                            <p>Premium, Multiple</p>
                          </div> */}
                        </div>
                      </div>
                      <div>
                        {/*<div className="grid-col-5">
                            <div className="ticket-type-col">
                              <div className="text heading">
                                {t("Ticket Type")}
                              </div>
                              {reservationDetails?.booking_type_id !== 3 && (
                                <div>
                                  {seatTypes?.length > 0 &&
                                    seatTypes.map((seatType, seatTypeIndex) => (
                                      <div
                                        className="content"
                                        key={seatTypeIndex}
                                      >
                                        <div>
                                          <span className="">
                                            {seatType.ticket_type}&nbsp;
                                            {!isMobile && "-"}&nbsp;
                                          </span>
                                          {seatType.seat_types.map(
                                            (item, index) => (
                                              <span
                                                className={`${
                                                  isMobile ? "d-block" : ""
                                                }`}
                                              >
                                                {item}
                                                {seatType.seat_types.length -
                                                  1 !==
                                                  index && ", "}
                                              </span>
                                            )
                                          )}
                                        </div>
                                      </div>
                                    ))}
                                </div>
                              )}
                            </div>
                            {reservationDetails?.fnb_items?.length > 0 && (
                              <div className="ticket-type-col">
                                <div className="heading">
                                  {t("Concessions")}
                                </div>

                                <div className="comma-separated content">
                                  {bookingDetails.fnb_items.map((item) => (
                                    <span>
                                      {item?.fst_quantity}&nbsp;
                                      {getLangSpecificAttribute(
                                        item?.FnbContent,
                                        lang_id,
                                        "item_lang_name"
                                      ) || item?.item_name}
                                    </span>
                                  ))}
                                </div>
                              </div>
                            )}
                          </div>*/}
                      </div>
                      <div className="ticket_bottom">
                        <div className="grid-col-2 mt-n2">
                          {/*{reservationDetails?.screening_name && (
                            <div>
                              <div className="heading">
                                {t("Screening Type")}:
                              </div>
                              <div className="content">
                                {" "}
                                {/* {booking_type === 1
                          ? t("Confirmed Screening")
                          : booking_type === 2
                          ? t("Crowdsourced Screening")
                          : booking_type === 3
                          ? t("Private Screening")
                          : t("Regular")}
                                {t(reservationDetails?.screening_name)}
                              </div>
                        </div>
                          )}*/}

                          <div
                            className={`booking_grid_align ${
                              isMobile ? '' : 'col-10'
                            }`}
                          >
                            <div className="heading heading_mob_down">
                              {t('BOOKING / FOOD & BEVERAGE SUMMARY')}
                            </div>
                            {/*<div className="booking_data_final">
                             <p>(x2 Seats) Standard Adult</p>
                             <p>CHF 30.00</p>
                             <p>(x2 Seats) Standard Adult</p>
                             <p>CHF 30.00</p>
                              <p>(x2 Seats) Standard Adult</p>
                             <p>CHF 30.00</p>
                            </div>*/}
                            {reservationDetails?.booking_type_id !== 3 && (
                              <>
                                <article
                                  className="payment_details_rows"
                                  style={{
                                    border: 'unset',
                                    paddingBottom: 0,
                                    marginBottom: '8px',
                                    color: '#000',
                                  }}
                                >
                                  {extractSeatTypes(
                                    reservationDetails?.selectedSeats || [],
                                  )?.length > 0 &&
                                    extractSeatTypes(
                                      reservationDetails?.selectedSeats || [],
                                    )?.map((seatType, seatTypeIndex) => (
                                      <>
                                        {seatType?.seats?.length > 0 &&
                                          seatType?.seats?.map(
                                            (rcg_child, index2) => (
                                              <section className="">
                                                <p className="left_value">
                                                  <span className="ml-0">
                                                    (x{rcg_child.no_of_seats}{' '}
                                                    {t('Seats')})
                                                  </span>
                                                  <span>
                                                    {seatType.sst_seat_type}
                                                  </span>
                                                  <span className="">
                                                    {rcg_child
                                                      ?.ticket_type_translations?.[
                                                      iso_2?.toLowerCase()
                                                    ] || rcg_child.tt_name}
                                                  </span>
                                                </p>

                                                <p className="right_value">
                                                  {curr_code}{' '}
                                                  {currencyFormatter(
                                                    rcg_child?.total_price,
                                                  )}
                                                </p>
                                              </section>
                                            ),
                                          )}
                                      </>
                                    ))}
                                </article>
                              </>
                            )}
                            {reservationDetails?.private_rate_card_detail &&
                              reservationDetails?.private_rate_card_detail.length >
                                0 && (
                                <article
                                  className="payment_details_rows"
                                  style={{
                                    border: 'unset',
                                    paddingBottom: 0,
                                    marginBottom: '8px',
                                    color: '#000',
                                  }}
                                >
                                  <section>
                                    <div className="left_value">
                                      {/*{<span className="ml-0">{reservationDetails?.private_rate_card_detail[0]?.customSeatCount} {t("Seats")}</span>}*/}
                                      {
                                        <span className="ml-0">
                                          {t('entire-hall-booked')}
                                        </span>
                                      }
                                      <span></span>
                                    </div>
                                    <div className="right_value">
                                      {curr_code}{' '}
                                      {currencyFormatter(
                                        (reservationDetails
                                          ?.private_rate_card_detail[0]?.slab?.[0]
                                          ?.quantity || 0) *
                                          (reservationDetails
                                            ?.private_rate_card_detail[0]?.slab?.[0]
                                            ?.price || 0),
                                      )}
                                    </div>
                                  </section>
                                </article>
                              )}
                            {/* Gross addons on rate card */}
                            {add_ons_array &&
                              add_ons_array?.[0]?.gross_amount_addons &&
                              add_ons_array?.[0]?.gross_amount_addons?.length >
                                0 && (
                                <article
                                  className="payment_details_rows_final"
                                  style={{
                                    border: 'unset',
                                    paddingBottom: 0,
                                    marginBottom: '8px',
                                    color: '#000',
                                  }}
                                >
                                  <section className="">
                                    <p className="left_value">
                                      <span className="ml-0">{t('Addons')}</span>
                                      <span className=""></span>
                                    </p>
                                    <p
                                      className="right_value"
                                      style={{ textAlign: 'right' }}
                                    ></p>
                                  </section>

                                  {add_ons_array?.[0]?.gross_amount_addons?.map(
                                    (item, index) => (
                                      <>
                                        <section className="">
                                          <p className="left_value">
                                            <span className="ml-0">
                                              (x{item.quantity}){' '}
                                            </span>

                                            <span className="">
                                              {t(item.addons_name) ||
                                                item.addons_name}
                                            </span>
                                          </p>

                                          <p
                                            className="right_value"
                                            style={{ textAlign: 'right' }}
                                          >
                                            {curr_code}{' '}
                                            {currencyFormatter(item?.final_price)}
                                          </p>
                                        </section>
                                      </>
                                    ),
                                  )}

                                  <section className="mb-3">
                                    <p className="left_value">
                                      <span className="ml-0">
                                        {t('Addons')} {t('Total')}
                                      </span>
                                      <span className=""></span>
                                    </p>

                                    <p
                                      className="right_value"
                                      style={{ textAlign: 'right' }}
                                    >
                                      {curr_code}{' '}
                                      {currencyFormatter(
                                        getRateCardAddonsTotal(reservationDetails),
                                      )}
                                    </p>
                                  </section>
                                </article>
                              )}

                            {reservationDetails?.fnb_items &&
                              reservationDetails?.fnb_items.length > 0 && (
                                <article
                                  className="payment_details_rows"
                                  style={{
                                    border: 'unset',
                                    paddingTop: 0,
                                    paddingBottom: 0,
                                    marginBottom: '8px',
                                    color: '#000',
                                  }}
                                >
                                  {reservationDetails?.fnb_items.map((item) => (
                                    <section>
                                      <div className="left_value">
                                        {item?.ft_quantity && (
                                          <span className="ml-0">
                                            (x{item?.ft_quantity})
                                          </span>
                                        )}
                                        <span>
                                          {getLangSpecificAttribute(
                                            item?.FnbContent,
                                            lang_id,
                                            'item_lang_name',
                                          ) || item.item_name}
                                        </span>
                                      </div>
                                      <div className="right_value">
                                        {curr_code}{' '}
                                        {currencyFormatter(
                                          item.fp_total_amount * item?.ft_quantity,
                                        )}
                                      </div>
                                    </section>
                                  ))}
                                </article>
                              )}
                            {reservationDetails?.private_rate_card_detail &&
                              reservationDetails?.private_rate_card_detail.length >
                                0 &&
                              reservationDetails?.private_rate_card_detail[0]
                                ?.fnb &&
                              reservationDetails?.private_rate_card_detail[0]?.fnb
                                .length > 0 && (
                                <article
                                  className="payment_details_rows"
                                  style={{
                                    border: 'unset',
                                    paddingTop: 0,
                                    paddingBottom: 0,
                                    marginBottom: '8px',
                                    color: '#000',
                                  }}
                                >
                                  {reservationDetails?.private_rate_card_detail[0]?.fnb.map(
                                    (item) => (
                                      <section>
                                        <div className="left_value">
                                          {item?.quantity && (
                                            <span className="ml-0">
                                              (x{item?.quantity})
                                            </span>
                                          )}
                                          <span>{item.item_name}</span>
                                        </div>
                                        <div className="right_value">
                                          {curr_code}{' '}
                                          {currencyFormatter(item.price)}
                                        </div>
                                      </section>
                                    ),
                                  )}
                                </article>
                              )}
                            {reservationDetails?.private_rate_card_detail &&
                              reservationDetails?.private_rate_card_detail.length >
                                0 &&
                              reservationDetails?.private_rate_card_detail[0]
                                ?.addon &&
                              reservationDetails?.private_rate_card_detail[0]?.addon
                                .length > 0 && (
                                <article
                                  className="payment_details_rows"
                                  style={{
                                    border: 'unset',
                                    paddingTop: 0,
                                    paddingBottom: 0,
                                    marginBottom: '8px',
                                    color: '#000',
                                  }}
                                >
                                  {reservationDetails?.private_rate_card_detail[0]?.addon.map(
                                    (item) => (
                                      <section>
                                        <div className="left_value">
                                          {item?.quantity && (
                                            <span className="ml-0">
                                              (x{item?.quantity})
                                            </span>
                                          )}
                                          <span>{item.item_name}</span>
                                        </div>
                                        <div className="right_value">
                                          {curr_code}{' '}
                                          {currencyFormatter(item.price)}
                                        </div>
                                      </section>
                                    ),
                                  )}
                                </article>
                              )}

                            {!!reservationDetails?.offer_price && (
                              <article
                                className="payment_details_rows"
                                style={{
                                  border: 'unset',
                                  paddingTop: 0,
                                  color: '#000',
                                }}
                              >
                                <section>
                                  <div className="left_value">
                                    <span className="ml-0">
                                      ( - )
                                      {`${
                                        !!reservationDetails?.voucher_code ||
                                        !!reservationDetails?.voucher_code_fallback
                                          ? ` ${t('Voucher Code')} ${`(${
                                              reservationDetails?.voucher_code ||
                                              reservationDetails?.voucher_code_fallback
                                            })`}`
                                          : ` ${t('Discount')}`
                                      }`}
                                    </span>
                                  </div>
                                  <div className="right_value">
                                    {curr_code}{' '}
                                    {currencyFormatter(
                                      reservationDetails?.offer_price,
                                    )}
                                  </div>
                                </section>
                              </article>
                            )}

                            {!isMobile && (
                              <div>
                                <p className="use_bar">
                                  {t('*Use the same Bar code to pick F&B Items.')}
                                </p>
                              </div>
                            )}
                          </div>

                          <div>
                            <div className="heading heading_mob_down">
                              {t('Total Amount To Pay')}
                            </div>
                            <div className="content color-black">
                              {curr_code}{' '}
                              {currencyFormatter(
                                parseFloat(reservationDetails?.amount || 0),
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="grid-col-2">
                          {/* <div>
                      <p>Availability:</p>
                      <p>Minimum seats booked</p>
                    </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div className="col-md-12 col-lg-3 col-xl-3 right tick_mob">
              {/* <div> */}
              <button
                className={`${
                  reservationDetails?.booking_type_id === 3
                    ? 'ticket-btn-gold'
                    : 'ticket-btn share-btn'
                }`}
                onClick={() => setModalShow(true)}
              >
                {reservationDetails?.booking_type_id === 3 && (
                  <Sharegolden style={{ marginRight: '5px' }} />
                )}{' '}
                {t('Share Ticket')}
              </button>
              <button
                className={``}
                className={`
                          ${
                            reservationDetails?.booking_type_id === 3
                              ? 'ticket-btn-gold'
                              : 'ticket-btn email-btn'
                          }
                          
                          ${sendEmailLoading ? 'loader-text' : ''}
                          `}
                onClick={onClickEmailTicket}
              >
                {reservationDetails?.booking_type_id === 3 && (
                  <Emailgolden style={{ marginRight: '5px' }} />
                )}{' '}
                {t('email-ticket')}
              </button>
              {/* </div>
                    <div> */}

              {/* </div>
                    <div> */}

              <button
                className={`final_btn_main ${
                  reservationDetails?.booking_type_id === 3
                    ? 'final_btn_main_golden'
                    : ''
                }`}
                onClick={() => {
                  dispatch(clearState());
                  history.replace(`/`);
                }}
              >
                {t('Back to home')}
              </button>
            </div>
          </div>
        </>
      </div>

      <ShareTicketPopup
        link={`${window.location.protocol}//${window.location.host}/unpaid-ticket/${reservation_id}/${category_id}`}
        show={modalShow}
        onHide={() => setModalShow(false)}
        sharedObject="Ticket"
        message={getShareMessage(reservationDetails, lang_id)}
      />

      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        className="share-ticket-popup"
        size="lg"
        centered
        onHide={() => setActionsModal(false)}
        show={actionsModal}
      >
        <section className="wrapper container-fluid">
          <img
            src={close}
            className="close-btn-main"
            alt=""
            onClick={() => setActionsModal()}
          />
          <div className="text-center title-row">
            <p className="title">{t('Get Ticket')}</p>
            <div className="golden-ticket-btn-group mt-4">
              <button
                className={`btn-main ${sendEmailLoading ? 'loader-text' : ''}`}
                onClick={onClickEmailTicket}
              >
                {/* <img src={mail} alt="" /> */}
                <svg
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M29 5H3C2.44687 5 2 5.44687 2 6V26C2 26.5531 2.44687 27 3 27H29C29.5531 27 30 26.5531 30 26V6C30 5.44687 29.5531 5 29 5ZM27.75 8.4625V24.75H4.25V8.4625L3.3875 7.79063L4.61562 6.2125L5.95312 7.25313H26.05L27.3875 6.2125L28.6156 7.79063L27.75 8.4625ZM26.05 7.25L16 15.0625L5.95 7.25L4.6125 6.20937L3.38438 7.7875L4.24687 8.45938L14.9219 16.7594C15.2289 16.9979 15.6066 17.1273 15.9953 17.1273C16.3841 17.1273 16.7618 16.9979 17.0688 16.7594L27.75 8.4625L28.6125 7.79063L27.3844 6.2125L26.05 7.25Z"
                    fill="black"
                  />
                </svg>

                {t('email-ticket')}
              </button>
              {!guestUser && (
                <button
                  className={`btn-main ${sendSmsLoading ? 'loader-text' : ''}`}
                  onClick={onClickSmsTicket}
                >
                  {/* <img src={mobile} alt="" /> */}
                  <svg
                    width="14"
                    height="22"
                    viewBox="0 0 14 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12.4375 0.453125H1.5625C0.735156 0.453125 0.0625 1.12578 0.0625 1.95312V19.9531C0.0625 20.7805 0.735156 21.4531 1.5625 21.4531H12.4375C13.2648 21.4531 13.9375 20.7805 13.9375 19.9531V1.95312C13.9375 1.12578 13.2648 0.453125 12.4375 0.453125ZM12.25 19.7656H1.75V2.14062H12.25V19.7656ZM6.0625 17.375C6.0625 17.6236 6.16127 17.8621 6.33709 18.0379C6.5129 18.2137 6.75136 18.3125 7 18.3125C7.24864 18.3125 7.4871 18.2137 7.66291 18.0379C7.83873 17.8621 7.9375 17.6236 7.9375 17.375C7.9375 17.1264 7.83873 16.8879 7.66291 16.7121C7.4871 16.5363 7.24864 16.4375 7 16.4375C6.75136 16.4375 6.5129 16.5363 6.33709 16.7121C6.16127 16.8879 6.0625 17.1264 6.0625 17.375Z"
                      fill="black"
                    />
                  </svg>

                  {t('Send to mobile')}
                </button>
              )}
              <button
                className="btn-main"
                onClick={() => {
                  setActionsModal(false);
                  setModalShow(true);
                }}
              >
                {/* <img src={share} alt="" /> */}
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M18.625 0.625H1.375C0.960156 0.625 0.625 0.960156 0.625 1.375V18.625C0.625 19.0398 0.960156 19.375 1.375 19.375H9.8125C9.91562 19.375 10 19.2906 10 19.1875V17.875C10 17.7719 9.91562 17.6875 9.8125 17.6875H2.3125V2.3125H17.6875V9.8125C17.6875 9.91562 17.7719 10 17.875 10H19.1875C19.2906 10 19.375 9.91562 19.375 9.8125V1.375C19.375 0.960156 19.0398 0.625 18.625 0.625ZM13.3117 12.0484L14.5352 10.825C14.5599 10.8001 14.5772 10.7687 14.5851 10.7345C14.5929 10.7002 14.591 10.6645 14.5795 10.6313C14.568 10.5981 14.5475 10.5687 14.5202 10.5466C14.4928 10.5245 14.4599 10.5105 14.425 10.5062L10.2203 10.0141C10.1008 10 9.99766 10.1008 10.0117 10.2227L10.5039 14.4273C10.5227 14.582 10.7125 14.6477 10.8227 14.5375L12.0508 13.3094L18.0555 19.3141C18.1281 19.3867 18.2477 19.3867 18.3203 19.3141L19.3141 18.3203C19.3867 18.2477 19.3867 18.1281 19.3141 18.0555L13.3117 12.0484Z"
                    fill="black"
                  />
                </svg>

                {t('Share Ticket')}
              </button>
            </div>
          </div>
        </section>
      </Modal>
    </section>
  );
};

function getShareMessage(reservationDetails, lang_id) {
  return `Here is a ticket for ${
    getLangSpecificAttribute(
      reservationDetails?.languageSpecificImages,
      lang_id,
      'mc_title',
    ) || reservationDetails?.movie_title
  } - ${reservationDetails?.lang_name} ${reservationDetails?.mf_name} at ${
    reservationDetails?.cine_name
  }, on ${moment(reservationDetails?.show_date).format('ll')} at ${moment(
    reservationDetails?.show_time,
    'HH:mm:ss',
  ).format('HH:mm')}`;
}

export default withNamespaces()(FinalTicketUnpaid);
