import Api from './Api';

export default {
  JwtTokenCheck({ token }) {
    return Api().post('/api/cinema/check-jwt-token', { token });
  },
  Login(payload) {
    return Api().post('/api/cinema/sign-in', payload);
  },
  Register(payload) {
    return Api().post('/api/cinema/sign-up', payload);
  },
  GuestRegister(payload) {
    return Api().post('/api/guest-login', payload);
  },
  ContactusMail(payload) {
    return Api().post('/api/external/mail', payload);
  },
  GetUserHistory(payload) {
    return Api().post(
      `/api/cinema/get-users-history?cust_id=${
        payload.customer_id || Math.random()
      }`,
      payload,
    );
  },
  GetGuestHistory(payload) {
    return Api().post(
      `/api/external/guest-user-history?guest_id=${
        payload.guest_id || Math.random()
      }`,
      payload,
    );
  },
  UpdateUser(payload) {
    return Api().post(`/api/cinema/update-user`, payload);
  },
  UpdateUserLang(payload) {
    return Api().post(`/api/cinema/update-user-lang`, payload);
  },
  ForgotPassword(payload) {
    return Api().post(`/api/external/website-forgot-password`, payload);
  },
  VerifyOTP(payload) {
    return Api().post(`/api/external/website-verify-otp`, payload);
  },
  ResetPassword(payload) {
    return Api().post(`/api/external/website-change-password`, payload);
  },
  LoginViaOtp(payload) {
    return Api().post(`/api/external/website-login-via-otp`, payload);
  },
  VoucherQfxMail(payload) {
    return Api().post('/api/external/vouchermail', payload);
  },
};
